:root {
  /* Colors */
  --bc-white: #ffffff;
  --bc-black: #2c2a29;
  --bc-macondo: #fdda24;
  --bc-andino: #00c389;
  --bc-orquidea: #9063cd;
  --bc-alba: #ff7f41;
  --bc-flamenco: #f5b6cd;
  --bc-caribe: #59cbe8;
}

/* Fuentes */

/* Thin */
@font-face {
  font-family: "CIBFont";
  src: url(./assets/fonts/CIBFont/Thin/CIBFontSans-Thin.ttf) format("truetype"),
    url(./assets/fonts/CIBFont/Thin/CIBFontSans-Thin.woff) format("woff"),
    url(./assets/fonts/CIBFont/Thin/CIBFontSans-Thin.woff2) format("woff2");
  font-weight: 100;
}
/* Light */
@font-face {
  font-family: "CIBFont";
  src: url(./assets/fonts/CIBFont/Light/CIBFontSans-Light.ttf)
      format("truetype"),
    url(./assets/fonts/CIBFont/Light/CIBFontSans-Light.woff) format("woff"),
    url(./assets/fonts/CIBFont/Light/CIBFontSans-Light.woff2) format("woff2");
  font-weight: 300;
}
/* Regular */
@font-face {
  font-family: "CIBFont";
  src: url(./assets/fonts/CIBFont/Regular/CIBFontSans-Regular.ttf)
      format("truetype"),
    url(./assets/fonts/CIBFont/Regular/CIBFontSans-Regular.woff) format("woff"),
    url(./assets/fonts/CIBFont/Regular/CIBFontSans-Regular.woff2)
      format("woff2");
  font-weight: 400;
}
/* Bold */
@font-face {
  font-family: "CIBFont";
  src: url(./assets/fonts/CIBFont/Bold/CIBFontSans-Bold.ttf) format("truetype"),
    url(./assets/fonts/CIBFont/Bold/CIBFontSans-Bold.woff) format("woff"),
    url(./assets/fonts/CIBFont/Bold/CIBFontSans-Bold.woff2) format("woff2");
  font-weight: 700;
}
/* Numbers */
@font-face {
  font-family: "CIBFont-Num";
  src: url(./assets/fonts/CIBFont-Num/CIBFontNumerales-Color.ttf)
      format("truetype"),
    url(./assets/fonts/CIBFont-Num/CIBFontNumerales-Color.woff) format("woff"),
    url(./assets/fonts/CIBFont-Num/CIBFontNumerales-Color.woff2) format("woff2");
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "CIBFont", sans-serif !important;
  color: var(--bc-black) !important;
}

.bc-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.required-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff7f41;
}

.toast-success {
  background-color: #00c389 !important;
}
.toast-error {
  background-color: #9063cd !important;
}
.toast-info {
  background-color: #59cbe8 !important;
}
.toast-warning {
  background-color: #ff7f41 !important;
}
.bc-height {
  /* min-height: 85vh; */
  min-height: 78vh;
}

.cursor-pointer {
  cursor: pointer;
}

.background-color-info {
  background-color: #59cbe8 !important;
}

.pt-label-custom {
  padding-top: 0.9rem !important;
}

.form-control:focus {
  border-color: #00c389 !important;
  box-shadow: none !important;
}

.custom-select > option:focus {
  background: #00c389 !important;
}

select:focus option:focus {
  background: #00c389 !important;
}

.bc-space {
  white-space: pre-line;
}

.dropdown-menu.show {
  display: block;
  transform: translate(0px, 0px) !important;
}