$blue: #fdda24;
$indigo: #9063cd;
$purple: #9063cd;
$pink: #f5b6cd;
$red: #ff7f41;
$orange: #ff7f41;
$yellow: #fdda24;
$green: #00c389;
$teal: #00c389;
$cyan: #59cbe8;

$white: #ffffff;
$black: #2c2a29;

$secondary: $green;

$btn-border-radius: 2rem;

$link-color: $indigo;

$component-active-bg: $green;

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import '../node_modules/bootstrap/scss/dropdown';
